<template>
  <div class="academic-years">
    <lenon-list-page
      title="Generated Questions"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-profile-photo="true"
      search-placeholder="Search"
      :hide-table="true"
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -20px;"
        >
          <lenon-select
            v-model="selectedAcademicYearId"
            placeholder="Academic Year"
            :options="academicYears"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedTermId"
            placeholder="Select Term"
            :options="filteredTerms"
            label-name="name"
            value-name="ay_term_id"
            class="mr-1"
          />
          <lenon-button
            icon="SettingsIcon"
            label="Online Exams"
            variant="outline-primary"
            class="mr-1"
            tool-tip-text="Manage online exams(Multiple Choice)"
            @onClick="showExamModal()"
          />
          <lenon-button
            icon="SettingsIcon"
            label="Books"
            variant="outline-primary"
            tool-tip-text="Manage school books"
            @onClick="showBookModal(true)"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-button
            v-b-toggle.report
            icon-only
            icon="MoreVerticalIcon"
            variant="flat-primary"
          />
        </div>
      </template>
      <template slot="table-header">
        <div class="col-12">
          <b-collapse
            id="report"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedAcademicYearId"
                  placeholder="Academic Year"
                  :options="academicYears"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedTermId"
                  placeholder="Select Term"
                  :options="filteredTerms"
                  label-name="name"
                  value-name="ay_term_id"
                />
              </div>
              <div class="col-md-6 float-right mb-1">
                <lenon-button
                  icon="SettingsIcon"
                  label="Online Exams"
                  variant="outline-primary"
                  class="mr-1"
                  tool-tip-text="Manage online exams(Multiple Choice)"
                  @onClick="showExamModal()"
                />
              </div>
              <div class="col-md-6 float-right mb-1">
                <lenon-button
                  variant="outline-primary"
                  icon="SettingsIcon"
                  label="Books"
                  tool-tip-text="Manage school books"
                  @onClick="showBookModal(true)"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
      <template slot="table-content">
        <div class="d-flex flex-row flex-wrap">
          <question
            v-for="(item,i) in questions"
            :key="i"
            :item="item"
            @click="downloadAssessment"
          />
        </div>
      </template>
    </lenon-list-page>
    <books-setup
      :modal-opened="bookModalOpened"
      @modalClosed="closeModal('book')"
    />
    <online-exam-setup
      :modal-opened="examModalOpened"
      @modalClosed="closeModal('online')"
    />
  </div>
</template>

<script>
import {
  BCollapse, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import confirm from '@/lenon/mixins/confirm'
import BooksSetup from '@/views/documents/questions/BooksSetup.vue'
import Question from '@/views/documents/questions/Question'
import OnlineExamSetup from '@/views/documents/questions/OnlineExamSetup'

export default {
  name: 'Results',
  components: {
    OnlineExamSetup,
    Question,
    BooksSetup,
    LenonSelect,
    LenonListPage,
    LenonButton,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      tableLoading: false,
      selectedClassesForModal: [],
      selectedAcademicYearId: null,
      selectedTermId: null,
      bookModalOpened: false,
      examModalOpened: false,
    }
  },
  computed: {
    monitorSettings() {
      return +this.selectedAcademicYearId
          * +this.selectedTermId
    },
    previousSetting() {
      return this.$store.getters['documents/questionSettings']
    },
    questions() {
      return this.$store.getters['documents/questions']
    },
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    academicYear() {
      return this.academicYears.find(ay => ay.id === this.selectedAcademicYearId)
    },
    selectedTerm() {
      return this.terms.find(term => term.ay_term_id === this.selectedTermId)
    },
  },
  watch: {
    monitorSettings() {
      if (this.selectedTermId && this.selectedAcademicYearId) {
        this.$store.commit('documents/setQuestionSettings', {
          selectedAcademicYearId: this.selectedAcademicYearId,
          selectedTermId: this.selectedTermId,
        })
        this.fetchQuestions()
      }
    },
  },
  mounted() {
    this.setSettings()
    this.fetchBooks()
    this.fetchOnlineExams()
  },
  methods: {
    downloadAssessment(item) {
      this.tableLoading = true
      this.$store.dispatch('documents/downloadAssessment', item.id).then(res => {
        this.downloadFile(res.data?.path, item.title)
      }).finally(() => {
        this.tableLoading = false
      })
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'book':
          this.bookModalOpened = false
          break
        case 'online':
          this.examModalOpened = false
          break
      }
    },
    downloadFile(url, name) {
      const link = document.createElement('a')
      link.href = `${process.env.VUE_APP_STORAGE_PATH}/${url}`
      link.target = 'blank'
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    showExamModal() {
      this.examModalOpened = true
    },
    showBookModal() {
      this.bookModalOpened = true
    },
    setSettings() {
      this.selectedAcademicYearId = this.previousSetting?.selectedAcademicYearId
      this.selectedTermId = this.previousSetting?.selectedTermId
    },
    fetchBooks() {
      this.tableLoading = true
      this.$store.dispatch('documents/fetchBooks').finally(() => {
        this.tableLoading = false
      })
    },
    fetchOnlineExams() {
      this.$store.dispatch('documents/fetchOnlineExams')
    },
    fetchQuestions() {
      this.tableLoading = true
      this.$store.dispatch('documents/fetchQuestions', { ay: this.selectedAcademicYearId, term: this.selectedTerm.id }).finally(() => {
        this.tableLoading = false
      })
    },
  },
}
</script>

<template>
  <!--    OnlineExam Form-->
  <validation-observer
    ref="onlineExamForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateOnlineExamMode?'Update':'Create'} Online Exam`"
      :show="onlineExamModalOpened"
      :show-overlay="deletingOnlineExam"
      size="md"
      @onClose="closeOnlineExamModal()"
    >
      <error-display :error="error" />
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedOnlineExamId"
            :options="onlineExams"
            label-name="title"
            value-name="id"
            placeholder="All Online Exams"
            @input="populateOnlineExamsForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteOnlineExam()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetOnlineExamForm()"
          />
        </div>
      </b-row>
      <lenon-select
        v-model="onlineExam.question_source"
        name="type"
        placeholder="Select Questions Source"
        rules="required"
        label="Questions Source"
        :options="questionTypes"
        :disabled="updateOnlineExamMode"
      />
      <lenon-input
        v-if="onlineExam.question_source==='custom'"
        v-model="onlineExam.title"
        name="title"
        placeholder="Enter a title, eg. End of Term Exam, Section A."
        rules="required"
        label="Exam Title"
      />
      <lenon-select
        v-model="onlineExam.class_id"
        name="class_id"
        placeholder="Select Class"
        rules="required"
        label="Class"
        :options="classes"
        value-name="id"
        label-name="name"
      />
      <lenon-select
        v-model="onlineExam.subject_id"
        name="subject_id"
        placeholder="Select Subject"
        rules="required"
        label="Subject"
        :options="subjects"
        value-name="id"
        label-name="name"
      />
      <lenon-select
        v-if="onlineExam.question_source==='generated'"
        v-model="onlineExam.question_id"
        name="question_id"
        placeholder="Select Generated Question"
        rules="required"
        label="Questions for Exams"
        :options="generatedQuestions"
        value-name="id"
        label-name="title"
      />
      <lenon-date-picker
        v-model="onlineExam.start_time"
        name="start_time"
        placeholder="Select Exam Start Time"
        rules="required"
        label="Start Time"
        enable-time
      />
      <lenon-date-picker
        v-model="onlineExam.end_time"
        name="end_time"
        placeholder="Select Exam End Time"
        rules="required"
        label="End Time"
        enable-time
      />
      <lenon-input
        v-model="onlineExam.duration_in_minutes"
        name="duration"
        placeholder="Enter exam duration in minutes."
        rules="required"
        label="Exam Duration(Minutes)"
        type="number"
        readonly
      />
      <div
        v-if="onlineExam.question_source==='custom'"
        class="mb-1"
      >
        <small class="text-danger">
          <b><i>Note that options to objective questions must not exceed 5 (A up to E).</i></b>
        </small>
      </div>
      <div
        v-if="onlineExam.question_source==='custom'"
        class="mb-1"
      >
        <lenon-rich-text
          v-model="onlineExam.custom_questions"
          label="Custom Questions"
          placeholder="Copy and paste your custom questions here"
        />
        <!--        <lenon-file-button-->
        <!--          label="Upload Custom Questions"-->
        <!--          accept=".pdf,.doc,.docx"-->
        <!--          variant="outline-primary"-->
        <!--          class="mr-1"-->
        <!--          @onFileUpload="handleQuestionsUpload"-->
        <!--        />-->
        <!--        <b-badge-->
        <!--          v-if="onlineExam.custom_questions"-->
        <!--          variant="light-success"-->
        <!--        >-->
        <!--          Uploaded-->
        <!--          <feather-icon icon="CheckCircleIcon" />-->
        <!--        </b-badge>-->
      </div>
      <div
        v-if="onlineExam.question_source==='custom'"
        class="mb-1"
      >
        <lenon-rich-text
          v-model="onlineExam.answers_to_custom_questions"
          label="Answers to Custom Questions"
          placeholder="Copy and paste answers to custom questions here"
        />
        <!--        <lenon-file-button-->
        <!--          label="Upload Answers to Questions"-->
        <!--          accept=".pdf,.doc,.docx"-->
        <!--          variant="outline-primary"-->
        <!--          class="mr-1"-->
        <!--          @onFileUpload="handleAnswersUpload"-->
        <!--        />-->
        <!--        <b-badge-->
        <!--          v-if="onlineExam.answers_to_custom_questions"-->
        <!--          variant="light-success"-->
        <!--        >-->
        <!--          Uploaded-->
        <!--          <feather-icon icon="CheckCircleIcon" />-->
        <!--        </b-badge>-->
      </div>
      <lenon-input
        v-if="onlineExam.question_source==='custom'"
        v-model="onlineExam.total_questions"
        name="total_questions"
        placeholder="Enter total questions for custom exams questions"
        rules="required"
        label="Total Questions"
        type="number"
      />
      <div style="margin-bottom: 70px !important;" />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeOnlineExamModal()"
          />
          <lenon-button
            :label="updateOnlineExamMode?'Update':'Create'"
            :disabled="invalid"
            :loading="onlineExamLoading"
            loading-text="Loading..."
            @onClick="updateOnlineExamMode?updateOnlineExam():createOnlineExam()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect'
import LenonFileButton from '@/lenon/components/LenonFileButton'
import LenonFile from '@/views/documents/folders/File'
import LenonRichText from '@/lenon/components/LenonRichText'
import moment from 'moment'

export default {
  name: 'OnlineExamSetup',
  components: {
    LenonRichText,
    LenonFile,
    LenonFileButton,
    LenonMultiSelect,
    LenonTextArea,
    ErrorDisplay,
    LenonDatePicker,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
    BBadge,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      deletingOnlineExam: false,
      onlineExamModalOpened: false,
      updateOnlineExamMode: false,
      onlineExamLoading: false,
      selectedOnlineExamId: null,
      questionTypes: [
        { label: 'Custom Questions', value: 'custom' },
        { label: 'Generated Questions', value: 'generated' },
      ],
      onlineExam: {
        id: null,
        question_source: null,
        title: null,
        class_id: null,
        subject_id: null,
        question_id: null,
        start_time: null,
        end_time: null,
        duration_in_minutes: null,
        total_questions: null,
        custom_questions: null,
        answers_to_custom_questions: null,
      },
      generatedQuestions: [],
    }
  },
  computed: {
    startTime() {
      return this.onlineExam.start_time
    },
    endTime() {
      return this.onlineExam.end_time
    },
    validClassSubject() {
      return (+this.onlineExam.class_id) * (+this.onlineExam.subject_id)
    },
    docType() {
      return this.onlineExam.doc_type
    },
    onlineExams() {
      return this.$store.getters['documents/onlineExams']
    },
    teachers() {
      return this.$store.getters['termsClasses/teachers']
    },
    selectedOnlineExam() {
      return this
        .onlineExams.find(g => +g.id === +this.selectedOnlineExamId)
    },
    classes() {
      return this.$store.getters['termsClasses/termClasses']
    },
    subjects() {
      return this.$store.getters['studentGrading/subjects']
    },
  },
  watch: {
    startTime(val) {
      this.updateDuration()
    },
    endTime(val) {
      this.updateDuration()
    },
    validClassSubject(val) {
      if (val) {
        this.getGeneratedQuestions()
      } else {
        this.generatedQuestions = []
      }
    },
    allUsers(val) {
      if (val) {
        this.onlineExam.users = this.teachers
      } else {
        this.onlineExam.users = []
      }
    },
    modalOpened(opened) {
      this.onlineExamModalOpened = opened
    },
    selectedOnlineExamId(id) {
      if (!id) {
        this.resetOnlineExamForm()
      }
    },
    id(id) {
      if (id) {
        this.selectedOnlineExamId = id
      }
    },
  },
  methods: {
    handleQuestionsUpload(upload) {
      this.onlineExam.custom_questions = upload.file
    },
    handleAnswersUpload(upload) {
      this.onlineExam.answers_to_custom_questions = upload.file
    },
    updateDuration() {
      const date1 = moment(this.onlineExam.start_time)
      const date2 = moment(this.onlineExam.end_time)
      this.onlineExam.duration_in_minutes = date2.diff(date1, 'minutes')
    },
    getGeneratedQuestions() {
      this.deletingOnlineExam = true
      this.$http.get(`/documents/questions/generated?class_id=${this.onlineExam.class_id}&subject_id=${this.onlineExam.subject_id}`).then(res => {
        this.generatedQuestions = res.data
      }).finally(() => {
        this.deletingOnlineExam = false
      })
    },
    resetOnlineExamForm() {
      this.updateOnlineExamMode = false
      this.allUsers = false
      this.onlineExam = {
        id: null,
        title: null,
        question_source: null,
        class_id: null,
        subject_id: null,
        start_time: null,
        end_time: null,
        duration_in_minutes: null,
        question_id: null,
        total_questions: null,
        custom_questions: null,
        answers_to_custom_questions: null,
      }
      this.selectedOnlineExamId = null
      this.$refs.onlineExamForm.reset()
    },
    populateOnlineExamsForm(ef) {
      if (this.selectedOnlineExam) {
        this.updateOnlineExamMode = true
        this.onlineExam = {
          ...this.selectedOnlineExam,
        }
      } else {
        this.resetOnlineExamForm()
      }
    },
    closeOnlineExamModal() {
      this.onlineExamModalOpened = false
      this.$emit('modalClosed')
    },
    createOnlineExam() {
      this.error = {}
      this.onlineExamLoading = true
      this.$http.post('/documents/online-exams', this.onlineExam)
        .then(res => {
          this.showSuccess('Uploaded online exam successfully')
          this.$store.commit('documents/addOnlineExam', res.data)
          this.resetOnlineExamForm()
        })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to upload online exam')
        })
        .finally(() => {
          this.onlineExamLoading = false
        })
    },
    updateOnlineExam() {
      this.error = {}
      this.onlineExamLoading = true
      this.$http.put(`/documents/online-exams/${this.onlineExam.id}`, this.onlineExam)
        .then(res => {
          this.showSuccess('Updated online exam successfully')
          this.$store.commit('documents/updateOnlineExam', res.data)
          this.resetOnlineExamForm()
        })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to update online exam')
        })
        .finally(() => {
          this.onlineExamLoading = false
        })
    },
    deleteOnlineExam() {
      if (!this.selectedOnlineExamId) {
        this.showInfo('Please select a online exam ')
        return
      }
      this.deletingOnlineExam = true
      this.$http.delete(`/documents/online-exams/${this.selectedOnlineExamId}`)
        .then(() => {
          this.showSuccess('Deleted online exam successfully')
          this.$store.commit('documents/removeOnlineExam', this.selectedOnlineExamId)
          this.selectedOnlineExamId = null
          this.resetOnlineExamForm()
        })
        .catch(err => {
          logData(err)
          this.showError('Failed to delete online exam')
        })
        .finally(() => {
          this.deletingOnlineExam = false
        })
    },
  },
}
</script>
